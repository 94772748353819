import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { ForgotPassword, RequireNewPassword, SignIn, withAuthenticator, Greetings } from 'aws-amplify-react';
import awsConfig from './awsConfig';
import Home from './containers/Home';
import customTheme from './AWSAmplifyCustomTheme';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [      
      {
        name: 'utility',
        endpoint: awsConfig.utilityAPIGateway.URL,
        region: awsConfig.utilityAPIGateway.REGION,
        custom_header: async () => {
          return { 'Authorization': (await Auth.currentSession()).accessToken.jwtToken }
        }
      },
      {
        name: 'engage',
        endpoint: awsConfig.engageAPIGateway.URL,
        region: awsConfig.engageAPIGateway.REGION,
        custom_header: async () => {
          return { 'Authorization': (await Auth.currentSession()).accessToken.jwtToken }
        }
      },
      {
        name: 'admin',
        endpoint: awsConfig.adminAPIGateway.URL,
        region: awsConfig.adminAPIGateway.REGION,
        custom_header: async () => {
          return { 'Authorization': (await Auth.currentSession()).accessToken.jwtToken }
        }
      },
      {
        name: 'fwupdate',
        endpoint: awsConfig.firmwareUpdateAPIGateway.URL,
        region: awsConfig.firmwareUpdateAPIGateway.REGION,
        custom_header: async () => {
          return { 'Authorization': (await Auth.currentSession()).accessToken.jwtToken }
        }
      },
      {
        name: 'ignite',
        endpoint: awsConfig.igniteAPI.URL,
        region: awsConfig.igniteAPI.REGION,
        custom_header: async () => {
          return { 'Authorization': (await Auth.currentSession()).accessToken.jwtToken }
        }
      },
      {
        name: 'hawkbit-int',
        endpoint: awsConfig.hawkbit_int.URL,
        region: awsConfig.hawkbit_int.REGION,
        custom_header: async () => {
          return { 'Authorization': (await Auth.currentSession()).accessToken.jwtToken }
        }
      }
    ]
  }
});

class App extends Component {
  render() {
    return (
      <Home />
    );

  }
}

export default withAuthenticator(App, true, [<SignIn key='0' />, <ForgotPassword key='1' />, <RequireNewPassword key='2' />, <Greetings key='3' />], null, customTheme);

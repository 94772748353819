import React, { Component } from "react";
import { API } from "aws-amplify";
import { Button } from 'reactstrap';
import { Row, Alert } from "react-bootstrap";

export default class ChangeConfiguration extends Component {

	constructor(props) {
		super(props);

		this.state = {
			periodicSendToAWSIntervalSeconds: null,
			setResult: null,
			logLevel: null,
			logLevelToChange: 'warn',
			arbitPublishPayload: '',
			showAlert: false,
			currentAPIMessage: '',
			topicToPublish: 'configure',
			defaultTextInArbitBox: JSON.stringify({ "UCI_field": "value" }),
		}
	}

	static defaultProps = {
		uuid: null,
		config: null
	}

	componentDidMount() {
		this.extractInitialValues(this.props.config);
	}

	handleAlertDismiss = () => {
		this.setState({ showAlert: false, currentAPIMessage: '' });
	}

	isNumeric = (value) => {
		return /^\d+$/.test(value);
	}

	onChange = () => {
	}
	///
	setLogLevel = () => {
		let body = {
			"id": this.props.uuid, "topic": "configure", "message": "{\"logLevel\": \"" +
				this.state.logLevelToChange + "\"}"
		};
		this.publishToTopic(body);
	}

	publishToTopic = (body) => {
		//console.log(body);
		// post to publish to connector configure
		const myInit = {
			body: body
		};
		this.setState({ showAlert: true, currentAPIMessage: "attempting POST " + JSON.stringify(body) });
		API
			.post("utility", "publish", myInit)
			.then(response => {
				console.log(response);
				try {
					let body = response.body;
					if (body === "ok") {
						this.setState({ currentAPIMessage: "published successfully" });
					}
				} catch (e) {

				}
			})
			.catch(error => {
				console.log(error.response);
				this.setState({ currentAPIMessage: "error in publishing" });
			});
	}

	setPeriodicSendToAWSIntervalSeconds = () => {
		if (!this.isNumeric(this.state.periodicSendToAWSIntervalSeconds)) {
			this.setState({ setResult: "input is not a number" });
		} else {
			let updateFreq = parseInt(this.state.periodicSendToAWSIntervalSeconds);
			if (updateFreq < 30) {
				this.setState({ setResult: "too high a frequency" });
			} else if (updateFreq > 3600) {
				this.setState({ setResult: "too low a frequency" });
			} else {
				this.setState({ setResult: "attempting to set..." });
				let body = { "id": this.props.uuid, "topic": "configure", "message": "{\"periodicSendToAWSIntervalSeconds\": \"" + this.state.periodicSendToAWSIntervalSeconds + "\"}" };
				this.publishToTopic(body);
			}
		}
	}

	arbitraryPublish = () => {
		console.log(this.state.arbitPublishPayload);
		console.log(this.state.topicToPublish);
		let body = { "id": this.props.uuid, "topic": this.state.topicToPublish, "message": this.state.arbitPublishPayload };
		this.publishToTopic(body);
	}

	updateLogLevelToChange = (event) => {
		this.setState({ logLevelToChange: event.target.value });
	}

	updateTopicToPublish = (event) => {
		console.log(event.target.value);
		let defText;
		if (event.target.value === 'configure') {
			defText = JSON.stringify({ "UCI_field": "value" });
		} else if (event.target.value === 'execute') {
			defText = JSON.stringify({ "command": "value", "cmd": "value", "signature": "value" });
		}
		//console.log(defText);
		this.setState({ topicToPublish: event.target.value, defaultTextInArbitBox: defText })
	}

	extractInitialValues = (config) => {
		//let updateFreq = 'Current value of update frequency (seconds): ';
		let freq = 'no data';
		let _logLevel = 'no data';
		if (config) {
			freq = config.hasOwnProperty('periodicSendToAWSIntervalSeconds') ?
				config.periodicSendToAWSIntervalSeconds : 'no data';
			_logLevel = config.hasOwnProperty('logLevel') ?
				config.logLevel : _logLevel;
			this.setState({
				periodicSendToAWSIntervalSeconds: freq,
				logLevel: _logLevel
			});
		}
		//return parseInt(freq);
	}

	render() {
		return (
			<div>
				<b>Change configuration</b>
				{this.state.showAlert && (
					<Alert variant="primary" onClose={this.handleAlertDismiss} dismissible>
						{this.state.currentAPIMessage}
					</Alert>
				)}
				<p>
					<label>Current value of update frequency (seconds):
						<input type="number" name="freq" min="10" max="3600"
							step="10" value={this.state.periodicSendToAWSIntervalSeconds || '0'}
							onChange={event => this.setState({ periodicSendToAWSIntervalSeconds: event.target.value })}>
						</input>
					</label>
					<Button color="primary" onClick={this.setPeriodicSendToAWSIntervalSeconds}>
						Change
					</Button>
				</p>

				<label>Current value of log level: <b>{this.state.logLevel}.</b></label>
				<p>Change the value to</p>
				<input type="radio" name="logLevel" id="warnLevel" value="warn" defaultChecked onChange={event => this.updateLogLevelToChange(event)} /> Warn &nbsp;
				<input type="radio" name="logLevel" id="errorLevel" value="error" onChange={event => this.updateLogLevelToChange(event)} /> Error &nbsp;
				<input type="radio" name="logLevel" id="infoLevel" value="info" onChange={event => this.updateLogLevelToChange(event)} /> Info &nbsp;
				<input type="radio" name="logLevel" id="debugLevel" value="debug" onChange={event => this.updateLogLevelToChange(event)} /> Debug &nbsp; &nbsp;
				<Button color="primary" onClick={this.setLogLevel}>
					Change
				</Button>

				<div>
					<Row>
						<input type="radio" name="publishTopic" id="configure" value="configure" defaultChecked onChange={event => this.updateTopicToPublish(event)} /> Configure &nbsp;
						<input type="radio" name="publishTopic" id="execute" value="execute" onChange={event => this.updateTopicToPublish(event)} /> Execute &nbsp;
					</Row>
					<Row> <textarea
						name="arbitPublish"
						defaultValue={this.state.defaultTextInArbitBox}
						rows={6}
						cols={30}
						onChange={e => this.setState({ arbitPublishPayload: e.target.value })}
					/>
					</Row>
					<Row>
						<Button disabled={this.state.arbitPublishPayload === '' ? true : false} color="primary"
							onClick={this.arbitraryPublish}>
							Publish
						</Button>
					</Row>
				</div>
			</div>
		);
	}
}

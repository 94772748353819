import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import AssetData from '../utility/AssetData';
import { Dropdown, DropdownButton, Button, Row, Col } from 'react-bootstrap';

const HawkbitInt = (props) => {
	//const HawkbitInt = ({ ethMAC, methodName }) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [selectedVersion, setSelectedVersion] = useState('Select a version');
	const [selectedDS, setSelectedDS] = useState(-1);
	const [versionSelected, setVersionSelected] = useState(false);

	const checkHawkbitAction = () => {
		setData(null);
		let body = { "targetId": props.ethMAC };
		const myInit = {
			body: body
		};
		API
			.post("hawkbit-int", "actions", myInit)
			.then(response => {
				// change reportedAt field in response to string for readability
				let convArray = AssetData.convertReportedAtToDateString(response.body.content);
				setData(convArray);
				try {

				} catch (e) { }
			})
			.catch(error => {
				console.log(error.response.data);
				setError(JSON.stringify(error.response.data));
			});
	};

	const getDS = () => {
		let body = {
			"url": "https://hyconhawk.eu-central-1.elasticbeanstalk.com/rest/v1/distributionsets?limit=250",
			"proxy_direct": "true"
		}
		const myInit = {
			body: body
		};
		console.log(myInit);
		API
			.post("hawkbit-int", "actions", myInit)
			.then(response => {
				try {
					setData(AssetData.extractVersionAndIdFromDSGet(response.content));
					console.log(data);
				} catch (e) { }
			})
			.catch(error => {
				console.log(error.response.data);
				setError(JSON.stringify(error.response.data));
			});
	};

	const assignDS = (targetId, dsId) => {
		let assignBody =
		[{
			"forcetime": Date.now(),
			"id": targetId,
			"type": "forced"
		}];
		let body = {
			"url": "https://hyconhawk.eu-central-1.elasticbeanstalk.com/rest/v1/distributionsets/" + dsId + "/assignedTargets",
			"proxy_direct": "true",
			"proxy_body": JSON.stringify(assignBody)
		}
		const myInit = {
			body: body
		};
		console.log(myInit);
		API
			.post("hawkbit-int", "actions", myInit)
			.then(response => {
				try {
					console.log(response.content);
				} catch (e) { }
			})
			.catch(error => {
				console.log(error.response.data);
				setError(JSON.stringify(error.response.data));
			});
	};

	useEffect(() => {
		if (props.methodName === 'distributionsets') {
			getDS();
		} else if (props.methodName === 'actions') {
			checkHawkbitAction();
		}
	}, []);

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (!data) {
		return <div>Loading...</div>;
	}
	const handleSelect = (eventKey) => {
		const selectedItem = JSON.parse(eventKey); // Parse the string back to an object
		console.log(`Selected Item:`, selectedItem);
		setSelectedVersion(selectedItem.version); // Update the state with the selected version
		setSelectedDS(selectedItem.id);
		setVersionSelected(true);
	};

	const assignFW = () => {
		if (!window.confirm('You are about to assign firmware ' + 
					selectedVersion + ' to target ' + props.doorName + '. Are you sure?' )) 
					return;

		assignDS(props.ethMAC.toUpperCase(), selectedDS);
	}

	return (
		<div>
			{props.methodName === 'actions' &&
				<div>
					<Row> <Col md="6" lg="6">
						<textarea name="actionResponse"
							defaultValue={JSON.stringify(data, null, 2)}
							rows={10}
							cols={80}
						/>
					</Col>
						<Col md="2" lg="2">
							<Button onClick={checkHawkbitAction}> Refresh </Button>
						</Col>
					</Row>
				</div>
			}
			{
				props.methodName === 'distributionsets' && <div>
					{<div>
						<Row> <Col md="3" lg="3">
							<h5>Assign a firmware version</h5> &nbsp;
							<DropdownButton
								id="dropdown-basic-button"
								title={selectedVersion}
								onSelect={handleSelect}
							>
								{data.map(item => (
									<Dropdown.Item
										key={item.id}
										eventKey={JSON.stringify({ id: item.id, version: item.version })}
									>
										{item.version}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Col>
							<Col md="2" lg="2">
								{versionSelected && <Button onClick={assignFW}> Assign </Button>}
							</Col>
						</Row>
					</div>}
				</div>
			}

		</div>
	);
};

export default HawkbitInt;

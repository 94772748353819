const AssetData = {
	formatForMapsMarker(data) {
		var geos = [];
		try {
			for (var i = 0; i < data.length; i++) {
				let x = data[i];
				if (!x.hasOwnProperty('metadata')) {
					if (x.hasOwnProperty('_original')) {
						x = x._original;
					}
				}

				if (x.metadata.geolocation) {
					let thisGeo = x.metadata.geolocation;
					geos.push({
						'geo': thisGeo, 'uuid': x.uuid, 'doorname': x.doorname, 'model': x.model, 'shadowUpdated': x.shadowUpdated
					});
				}
			}
		} catch (e) {
			console.log('exception in formatForMapsMarker: ' + e);
		}
		return geos;
	},

	formatForInfoWindow(x) {
		return "<p>" + x.doorname + "</p>" +
			"<p>" + x.model + "</p>" +
			"<p>" + this.getNumTimescaleAgo(x.shadowUpdated * 1000) + "</p>";

	},
	getNumTimescaleAgo(shadowUpdated) {
		let diff = Math.ceil(Math.abs(Date.now() - shadowUpdated) / 60000);
		if (diff <= 1) {
			return "Now";
		}
		else if (diff <= 60) {
			return diff.toString() + " m";
		}
		else if (diff > 60 && diff <= 1440) {
			return (Math.ceil(diff / 60)).toString() + " h";
		} else {
			return (Math.ceil(diff / 1440)).toString() + " d";
		}
	},
	shouldStartRealTimeStreaming(shadowUpdated, updateFreq) {
		let diff = Math.ceil(Math.abs(Date.now() - shadowUpdated) / 1000);
		return (diff <= updateFreq);
	},
	extractNumberFromVersion(firmwareVersion) {
		let arr = firmwareVersion.split("-");
		return arr[arr.length - 1].replace(/\D/g, '');
	},
	convertReportedAtToDateString(jsonArray) {
		// Deep copy the entire array
		const arrayCopy = jsonArray.map(jsonObj => JSON.parse(JSON.stringify(jsonObj)));

		return arrayCopy.map(item => {
			// Convert 'reportedAt' values
			if (item.reportedAt) {
				item.reportedAt = new Date(item.reportedAt).toLocaleString();
			}
			// Convert back to JSON string
			return item;
		});
	},
	extractVersionAndIdFromDSGet(jsonArray) {
		return jsonArray.map(item => ({
			id: item.id,
			version: item.version
		}));
	}
}

export default AssetData;
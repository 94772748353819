import React, { Component } from "react";
import { Row, Col, Dropdown, Button, Tabs, Tab, Alert } from "react-bootstrap";
import ReactTable from 'react-table';
import MapView from "../components/MapView";
import StatusIndicator from "../components/StatusIndicator";
import FirmwareDisplay from "../components/FirmwareDisplay";
import AssetDetailView from "./AssetDetailView";
import AssetData from '../utility/AssetData';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import CustomerStats from "../components/CustomerStats";

export default class AssetTable extends Component {

	constructor(props) {
		super(props)

		this.handler = this.handler.bind(this);
		this.mapRef = React.createRef();
		this.changeAssetList = this.changeAssetList.bind(this);

		this.state = {
			clickedOnUUID: '',
			customers: null,
			selectedCustomer: '',
			stackedView: true,
			selectedCustomerInfo: null,
			showCustomerInfo: false,
			customDataReduced: [],
			showReducedData: false,
			willChangeAssets: true,
			haveChangedAssets: false,
			tabs: [],
			newTabTitle: '',
			activeKey: 'all',
			showAlert: false,
			alertMsg: '',
			alertVariant: 'primary',
		};
	}

	static defaultProps = {
		data: []
	}

	componentDidMount() {
		let _customers = this.extractListofCustomers(this.props.data);
		this.setState({ customers: _customers, customDataReduced: this.props.data });
		setTimeout(() => { this.defaultZoomAndCenter() }, 2000);

	}

	handleAddTab = (newTabTitle, renderFunction) => {
		const { tabs } = this.state;
		if (newTabTitle.trim() === '') return; // Prevent adding empty tab titles
		const newTabKey = newTabTitle.replace(/\s+/g, '-').toLowerCase();
		// Check for duplicate tab
		if (tabs.some(tab => tab.key === newTabKey)) {
			this.setState({ showAlert: true, alertMsg: 'A tab for this asset already exists.', alertVariant: 'danger' });
			return;
		}
		const newTab = {
			title: newTabTitle,
			key: newTabKey,
			renderContent: renderFunction,
			content: newTabTitle
		};
		this.setState({
			tabs: [...tabs, newTab],
			activeKey: newTab.key,
		});
	};

	handleRemoveTab = (key, event) => {
		event.stopPropagation(); // Prevent switching to the tab when closing it
		const { tabs, activeKey } = this.state;
		const filteredTabs = tabs.filter(tab => tab.key !== key);
		let newActiveKey = activeKey;

		if (activeKey === key && filteredTabs.length > 0) {
			newActiveKey = filteredTabs[0].key;
		} else if (filteredTabs.length === 0) {
			newActiveKey = 'all';
		}

		this.setState({
			tabs: filteredTabs,
			activeKey: newActiveKey,
		});
	};

	handleAlertDismiss = () => {
		this.setState({ showAlert: false });
		if (this.selectCustomer !== '') {
			this.defaultZoomAndCenter();
		}
	};

	handleSelect = (key) => {
		this.setState({ activeKey: key });
	};

	extractListofCustomers(assets) {
		//console.log(assets);
		let customers = [];
		for (var i = 0; i < assets.length; i++) {
			if (assets[i].hasOwnProperty('customer') && assets[i]['customer']) {
				customers.push(assets[i].customer);
			}
		}
		customers.push('All');
		let customerSet = [...new Set(customers)];
		return customerSet.sort(function (a, b) {
			if (a && b) {
				a = a.toLowerCase();
				b = b.toLowerCase();
				if (a === b) return 0;
				return a < b ? -1 : 1;
			} else return 0;
		});
	}

	handler(uuid) {
		if (uuid && uuid !== '') {
			this.setState({ clickedOnUUID: uuid });
		} else {  // if blank uuid passed, reset
			this.setState({
				clickedOnUUID: '', selectedCustomer: '', selectedCustomerInfo: null,
				showCustomerInfo: false, showReducedData: false
			});
		}
	}

	selectCustomer(customer) {
		//console.log(customer);
		this.setState({ showAlert: true, alertMsg: 'Selecting customer ' + customer + '. Dismiss this alert to clear selection', 
						alertVariant: 'primary' });
		this.setState({ selectedCustomerInfo: null, showCustomerInfo: false });
		if (customer === 'All') {
			customer = '';
		} else {
			//this.getCustomerStats(customer);
		}

		//first, partially execute the logic of 'all assets'
		this.defaultZoomAndCenter();

		//then set customer name
		setTimeout(() => { this.setState({ selectedCustomer: customer }) }, 700);
		//this.setState({ selectedCustomer: customer });
	}

	displayCustomersDropdown() {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					Customers
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{
						this.state.customers.map((value) => {
							return (
								<Dropdown.Item key={value} onClick={this.selectCustomer.bind(this, value)}>{value}</Dropdown.Item>
							)
						})
					}

				</Dropdown.Menu>
			</Dropdown>);
	}

	changeAssetList() {
		//console.log('changeAssetList::willChangeAssets = ' + this.state.willChangeAssets);
		if (this.state.willChangeAssets === false) {
			this.setState({ haveChangedAssets: false });
			return;
		}

		if (this.mapRef.current.state._map) {
			var currBounds = this.mapRef.current.state._map.getBounds();
			var inBoundsData = [];
			for (let i = 0; i < this.props.data.length; i++) {
				if (this.props.data[i].metadata && this.props.data[i].metadata.geolocation) {
					var xy = this.props.data[i].metadata.geolocation.split(",");
					var x = xy[0].trim();
					var y = xy[1].trim();
					let position = { lat: parseFloat(x), lng: parseFloat(y) };
					if (currBounds.contains(position)) {
						inBoundsData.push(this.props.data[i]);
					}
				}
			}

			this.setState({
				customDataReduced: inBoundsData, showReducedData: true, haveChangedAssets: true,
				willChangeAssets: true
			});
		}
	}

	defaultZoomAndCenter() {
		this.setState({ showReducedData: false, willChangeAssets: false });
		this.mapRef.current.defaultZoomAndCenter();
		// delay 2 seconds and re-enable asset list change with zoom
		setTimeout(() => { this.setState({ willChangeAssets: true }) }, 2000);
	}

	changeView() {
		this.setState({ stackedView: !this.state.stackedView });
		// also execute the logic of 'all assets'
		this.defaultZoomAndCenter();

	}

	processFilteredChange(filtered) {
		// if filtered array is empty, means nothing is being searched for
		// trigger the same as clicking on all assets
		let filterIsEmpty = true;
		if (filtered.length !== 0) {
			filtered.forEach((c) => {
				if (c.value !== "") {
					filterIsEmpty = false;
				}
			});
		}
		if (filterIsEmpty) {
			this.setState({ showAlert: true, alertMsg: 'Resetting selection', alertVariant: 'primary' });
			// call this after 1 second
			setTimeout(() => { this.defaultZoomAndCenter() }, 1000);
		}
	}

	renderHeader() {
		return (<><Col md={2}> {this.state.customers && this.displayCustomersDropdown()}
			<br />
			{this.state.selectedCustomer &&
				<Button onClick={() => { this.setState({ showCustomerInfo: true }) }}> Show Info on {this.state.selectedCustomer}
				</Button>
			}
		</Col>
			<Col md={1}> <Button onClick={this.changeView.bind(this)}>
				{this.state.stackedView ? 'Side View' : 'Stacked view'}
			</Button>  </Col>
			<Col md={1}> <Button onClick={this.defaultZoomAndCenter.bind(this)}>
				All Assets
			</Button>  </Col>
			<Col>
				{this.state.selectedCustomer && this.state.showCustomerInfo && <label>Selected <i>{this.state.selectedCustomer}</i>. Click on "All Assets" to reset selection. </label>}
				<br />
				{this.state.showCustomerInfo && <CustomerStats customerName={this.state.selectedCustomer}> </CustomerStats>}
				{!this.state.showCustomerInfo && <CustomerStats customerName={'*'}> </CustomerStats>}
			</Col>
			<Col></Col>
		</>);
	}

	renderMap() {
		return (<MapView data={this.props.data} handler={this.handler} ref={this.mapRef}
			changeAssetList={this.changeAssetList} />);
	}

	renderDebug() {
		return (<div> <p> showReducedData: {this.state.showReducedData.toString()} </p>
			<p> haveChangedAssets: {this.state.haveChangedAssets.toString()} </p>
			<p> willChangeAssets: {this.state.willChangeAssets.toString()} </p>
			<p> customReduced.length: {this.state.customDataReduced.length.toString()} </p>
		</div>
		);
	}

	renderAssetDetailView(selectedRow) {
		return (<AssetDetailView uuid={selectedRow.uuid} data={selectedRow} />)
	}

	renderTable() {
		return (<ReactTable
			onFetchData={(state, instance) => {
				if (state.sortedData.length > 0) {
					// iterate through sorted data
					let geos = AssetData.formatForMapsMarker(state.sortedData);
					this.mapRef.current.recenterMapBySelected(geos);
				}
			}}
			onFilteredChange={(filtered) => this.processFilteredChange(filtered)}
			getTdProps={(state, rowInfo, column, instance) => {
				return {
					onClick: (e, handleOriginal) => {
						if (column.Header) {
							if (column.Header === 'UUID') {
								// spawn new tab
								this.handleAddTab(rowInfo.original.uuid, this.renderAssetDetailView(rowInfo.original));
							} else if (column.Header === 'Customer') {
								// do the logic of select customer
								this.selectCustomer(rowInfo.original.customer);
							}
						}
						handleOriginal();
					}
				}
			}}
			className="-striped -highlight"
			defaultPageSize={30}
			data={this.state.showReducedData ? this.state.customDataReduced : this.props.data}
			defaultFiltered={[
				{
					id: 'uuid',
					value: this.state.clickedOnUUID
				},
				{
					id: 'customer',
					value: this.state.selectedCustomer
				}
			]}
			columns={[
				{ Header: 'UUID', accessor: 'uuid', filterable: true, maxWidth: 370 },
				{ Header: 'Type', accessor: 'model', filterable: true, maxWidth: 100 },
				{ Header: 'Door name', accessor: 'doorname', filterable: true, maxWidth: 400 },
				{ Header: 'Customer', accessor: 'customer', filterable: true, maxWidth: 400 },
				{ Header: 'Ethernet MAC', accessor: 'ethMAC', filterable: true, maxWidth: 200 },
				{ Header: 'Commissioned', accessor: 'created', filterable: true, maxWidth: 150 },
				{
					Header: 'Version', accessor: 'firmwareVersion', filterable: true, maxWidth: 100,
					Cell: props => (props.value ? <FirmwareDisplay firmwareVersion={props.original.firmwareVersion} fullDisplay={false} /> : null)
				},
				{
					Header: 'Last Updated', accessor: 'shadowUpdated', maxWidth: 80,
					Cell: props => (props.value ? <StatusIndicator date={props.value * 1000} /> : null)
				},
				{
					Header: 'Ping', accessor: 'ping', maxWidth: 80,
					Cell: props => (props.value ? <StatusIndicator date={props.value * 1000} /> : null)
				}
			]}
			defaultSorted={[{ id: "shadowUpdated", desc: true }]}
			defaultFilterMethod={(filter, row) => {
				const id = filter.pivotId || filter.id;
				return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
			}}
		//SubComponent={row => { return (<AssetDetailView uuid={row.original.uuid} data={row.original} />) }}
		/>);
	}

	renderStacked() {
		return (<div>
			<Row>	{this.renderHeader()} 	</Row>
			<Row> <Col> 	{this.renderMap()} 	</Col> </Row>
			<Row> <Col> {this.renderTableTab()} </Col> </Row></div>);
	}

	renderSide() {
		return (<div>
			<Row>	{this.renderHeader()} 	</Row>
			<Row> <Col md={5}> 	{this.renderMap()} 	</Col>
				<Col md={7}> {this.renderTableTab()} </Col> </Row></div>);
	}

	render() {
		return ((this.state.stackedView && this.renderStacked()) || (!this.state.stackedView && this.renderSide()));
	}

	renderTabTitle = (tab) => {
		return (
			<span>
				{tab.title}
				{tab.key !== 'all' && (
					<Button
						variant="link"
						size="sm"
						onClick={(event) => this.handleRemoveTab(tab.key, event)}
						className="close-button"
					>
						&times;
					</Button>
				)}
			</span>
		);
	};

	renderTableTab() {
		return (
			<div>{this.state.showAlert && (
				<Alert variant={this.state.alertVariant} onClose={this.handleAlertDismiss} dismissible>
					{this.state.alertMsg}
				</Alert>
			)}
				<Tabs id="entrivo-mgmt" className="mb-3" activeKey={this.state.activeKey} onSelect={this.handleSelect}>
					<Tab eventKey='all' title="All assets">
						{this.renderTable()}
					</Tab>
					{this.state.tabs.map(tab => (
						<Tab eventKey={tab.key} title={this.renderTabTitle(tab)} key={tab.key}>
							{tab.renderContent}
						</Tab>
					))}
				</Tabs>
			</div>
		);
	}

}

AssetTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object)
};

AssetTable.defaultProps = {
	data: null
};
import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./AssetDetailView.css";
import DataTable from "../components/DataTable";
import HistoricalData from "../components/HistoricalData";
import PropTypes from 'prop-types';
import ChangeConfig from "../components/ChangeConfiguration";
import Actions from "../components/Actions";
import { API } from "aws-amplify";
import Collapsible from 'react-collapsible';
import AssetData from '../utility/AssetData';

function replacer(key, value) {
	// Filtering out properties
	if (value === 0) {
		return "0";
	}
	else if (value === true) {
		return "true";
	} else if (value === false) {
		return "false";
	} else {
		return value;
	}
}

export default class AssetDetailView extends Component {

	constructor(props) {
		super(props);

		this.state = {
			shadow: null,
			isLoading: true,
			hrfPing: '',
			hrfShadow: '',
			isShadowFresh: true,
			fwVersionNum: 0,
			localAssetData: null,
		}
	}

	static defaultProps = {
		uuid: null,
		data: {},
	}

	refreshSingleAsset() {
		this.setState({
			hrfPing: 'Getting latest data...',
			hrfShadow: 'Getting latest data...',			
			fwVersionNum: 'Getting latest data...',
			isShadowFresh: AssetData.shouldStartRealTimeStreaming(this.props.data.shadowUpdated * 1000,
				this.props.data.config.minSendToAWSIntervalSeconds),
		});
		API.get('utility', 'assets/' + this.props.uuid)
			.then(response => {
				console.log(response.body.result);
				let currData = response.body.result;
				let fwNum = 0;
				this.setState({ localAssetData: response.body.result });
				try {
					fwNum = parseInt(AssetData.extractNumberFromVersion(currData.firmwareVersion));
				} catch (e) { }
				this.setState({
					hrfPing: AssetData.getNumTimescaleAgo(currData.ping * 1000),
					hrfShadow: AssetData.getNumTimescaleAgo(currData.shadowUpdated * 1000),
					isShadowFresh: AssetData.shouldStartRealTimeStreaming(currData.shadowUpdated * 1000,
						this.props.data.config.minSendToAWSIntervalSeconds),
					fwVersionNum: fwNum
				});
			})
			.catch(error => {
				console.log(error);
			})
	}

	componentDidMount() {
		this.refreshSingleAsset();
		this.getShadow();
		/*let fwNum = 0;
		try {
			fwNum = parseInt(AssetData.extractNumberFromVersion(this.props.data.firmwareVersion));
		} catch (e) { }
		this.setState({
			hrfPing: AssetData.getNumTimescaleAgo(this.props.data.ping * 1000),
			hrfShadow: AssetData.getNumTimescaleAgo(this.props.data.shadowUpdated * 1000),
			isShadowFresh: AssetData.shouldStartRealTimeStreaming(this.props.data.shadowUpdated * 1000,
				this.props.data.config.minSendToAWSIntervalSeconds),
			fwVersionNum: fwNum
		});*/
	}

	toHumanReadableDate = (ts) => {
		if (ts == null) {
			return "no data";
		} else {
			let dt = new Date(ts * 1000);
			return dt.toString();
		}
	}

	getShadow = () => {

		this.setState({ isLoading: true }, () => {
			API
				.post("utility", "status/data", {
					'body': {
						'id': this.props.uuid
					}
				})
				.then(response => {
					try {
						let body = response.body;
						// use a replacer function to replace boolean with string
						// because json-to-table cannot handle boolens
						//this.props.data.shadow = JSON.parse(JSON.stringify(body, replacer));
						let shd = JSON.parse(JSON.stringify(body, replacer));
						shd['updated'] = this.toHumanReadableDate(this.props.data.shadowUpdated);
						shd['ping'] = this.toHumanReadableDate(this.props.data.ping);
						this.setState({ shadow: shd, isLoading: false });
					} catch (e) {
						this.setState({ shadow: 'No shadow available' });
					}
				})
				.catch(error => {
					console.log(error.response);
					this.setState({ shadow: 'No shadow available' });
				});
		});

	}

	render() {
		return (

			<Container fluid>
				<Row className="text-center"><Col md={12} xs={4}><h5>{this.props.uuid}</h5></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><h5>{this.props.data.doorname}</h5></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><h6>Last Shadow Update: {this.state.hrfShadow}</h6></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><h6>Last Ping: {this.state.hrfPing}</h6></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><h6>Ethernet MAC: {this.props.data.ethMAC}</h6></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><h6>First commissioned: {this.props.data.created}</h6></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><h6>Firmware version: {this.state.fwVersionNum}</h6></Col></Row>
				<Row className="text-center"><Col md={12} xs={4}><Button onClick={this.refreshSingleAsset.bind(this)} > Refresh all data </Button></Col></Row>

				<Actions
					fwVersionNum={this.state.fwVersionNum}
					isShadowFresh={this.state.isShadowFresh}
					ethMAC={this.props.data.ethMAC}
					productModel={this.props.data.model}
					uuid={this.props.data.uuid}
					doorName={this.props.data.doorname}
					meta={this.props.data.metadata} />
				<p></p>
				<Row>
					<Col md={4} xs={4}>
						<Button onClick={this.getShadow.bind(this)}> Refresh </Button>
						<p> </p>
						{!this.state.isLoading && <DataTable title={this.state.isShadowFresh ? "Shadow" : "STALE SHADOW!!"} data={this.state.shadow} />}
						{this.state.isLoading && <p> Getting shadow state</p>}
					</Col>
					<Col md={4} xs={4}>
						<DataTable title="Metadata" data={this.props.data.metadata} />
						<DataTable title="Configuration" data={this.props.data.config} />
					</Col>
					<Col md={4} xs={4}>
						<ChangeConfig uuid={this.props.uuid} config={this.props.data.config} />
					</Col>
				</Row>
				<Collapsible trigger={<p style={{ cursor: 'pointer' }}>&#9654; Historical Data</p>} transitionTime={100}>
					<HistoricalData uuid={this.props.uuid}></HistoricalData>
				</Collapsible>
			</Container>

		);
	}
}

AssetDetailView.propTypes = {
	uuid: PropTypes.string,
	data: PropTypes.object
};

AssetDetailView.defaultProps = {
	uuid: null,
	data: null
};
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import PropTypes from 'prop-types';

export default class DataTable extends Component {

    getDataCell(data) {
        if(typeof data === 'object') {
            return <td><DataTable data={data} /></td>
        }
        if (!data) data = "";
        if (!Array.isArray(data)) {
            return data.toString();
        }
        if (data.length === 1) {
            return data[0].toString();
        }
        return (<ul>{data.map((x, i) => (<li key={i}>{x}</li>))}</ul>);
    }

    getTableRows(data) {
        const items = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                items.push(<tr key={key}><td>{key}</td><td>{this.getDataCell(data[key])}</td></tr>);
            }
        }
        return items.length ? items : <tr><td>Data not available</td></tr>;
    }

    render() {
        return (<Table responsive striped bordered size="sm">
            <tbody>
                {this.props.title ? <tr><th colSpan="2">{this.props.title}</th></tr> : null}
                {this.getTableRows(this.props.data)}
            </tbody>
        </Table>);
    }
}

DataTable.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object
};

DataTable.defaultProps = {
    title: null,
    data: null
};
export default {
    button: {
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--dormakaba-blue')
    },
    navBar: {
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--dormakaba-light-grey')
    },
    navButton: {
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--dormakaba-red')
    },
};
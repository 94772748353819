import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { API } from "aws-amplify";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Alert } from "react-bootstrap";
import WebSocketComponent from "./WebSocketComponent";
import HawkbitInt from "./HawkbitInt";

export default class Actions extends Component {

	constructor(props) {
		super(props);

		this.state = {
			decommissionSuccessful: false,
			afterOpenClicked: null,
			shadow: null,
			hawkbitPing: null,
			isLoading: false,
			isLoadingHistorical: false,
			hasHistoricalData: false,
			changeModeDropdownOpen: false,
			showAlertsInputPage: false,
			showRemoteOpsStats: false,
			showAlert: false,
			currentAPIMessage: '',
			showRealTimeData: false,
			showingHawkbitStatus: false,
			showDS: false,
		}
	}

	static defaultProps = {
		uuid: null,
		meta: null,
		productModel: null,
		ethMAC: null
	}

	checkHawkbitStatus = () => {

		this.setState({ showAlert: true, currentAPIMessage: 'Getting hawkbit status', showingHawkbitStatus: true });
		let body = { "targetId": this.props.ethMAC };
		const myInit = {
			body: body
		};
		API
			.post("utility", "status/hawkbit", myInit)
			.then(response => {
				//console.log(response);
				try {
					let body = response.body;
					let pollStatus = body.pollStatus;
					let lastRequestAt = pollStatus.lastRequestAt;
					let diff = Date.now() - lastRequestAt;
					let unit = "seconds";
					diff = diff / 1000;

					if (diff > 86400) {
						diff = diff / 86400;
						unit = "days";
					} else if (diff <= 86400 && diff > 3600) {
						diff = diff / 3600;
						unit = "hours";
					} else if (diff < 3600 && diff > 60) {
						diff = diff / 60;
						unit = "minutes";
					}
					diff = Math.round(diff * 10) / 10;
					let hp = new Date(lastRequestAt);
					this.setState({ currentAPIMessage: "Last Hawkbit ping:\n" + hp + "\n" + diff + " " + unit + " ago" });

				} catch (e) { }
			})
			.catch(error => {
				console.log(error.response);
			});
	}

	// BODY='{"id": "", "command": "sudo reboot"}'
	rebootConnector = () => {

		if (!window.confirm('Are you sure you wish to reboot ' + this.props.doorName + '?')) return;

		this.setState({ showAlert: true, currentAPIMessage: 'Attempting to send reboot command' });
		let body = { "id": this.props.uuid, "topic": "execute", "message": "{\"command\": \"REBOOT\"}" };
		const myInit = {
			body: body
		};

		API
			.post("utility", "publish", myInit)
			.then(response => {
				try {
					let body = response.body;
					if (body === "ok") {
						this.setState({ currentAPIMessage: 'Reboot command reply: OK' });
					}
				} catch (e) {
					this.setState({ currentAPIMessage: 'Reboot command reply: Error' });
				}
			})
			.catch(error => {
				console.log(error.response);
				this.setState({ currentAPIMessage: 'Reboot command reply: Error' });
			});
	}

	getLogs = () => {

		this.setState({ showAlert: true, currentAPIMessage: 'Attempting to get IoT logs' });
		let body = { "id": this.props.uuid, "topic": "execute", "message": "{\"command\": \"SEND_LOGS\"}" };
		const myInit = {
			body: body
		};

		API
			.post("utility", "publish", myInit)
			.then(response => {
				console.log(response);
				try {
					let body = response.body;
					if (body === "ok") {
						console.log('reply is ok');
						this.setState({ currentAPIMessage: 'Reply of get logs is OK' });
					}
				} catch (e) {
					this.setState({ currentAPIMessage: 'Reply of get logs: Error' });
				}
			})
			.catch(error => {
				console.log(error.response);
				this.setState({ currentAPIMessage: 'Reply of get logs: Error' });
			});
	}

	sendRemoteOpen = () => {

		if (!window.confirm('Are you sure you wish to send remote open to ' + this.props.doorName + '?')) return;

		this.setState({ showAlert: true, currentAPIMessage: 'Attempting to send remote open' });
		let body = { "id": this.props.uuid, "topic": "execute" };
		body['message'] = this.props.productModel === 'EL301' ? "{\"command\": \"SECURITY_OPEN_PULSE\"}" :
			"{\"command\": \"SECURITY_OPEN\", \"options\": {\"pulse\": \"OPENING_PULSE_LONG\"}}";
		const myInit = {
			body: body
		};

		API
			.post("utility", "publish", myInit)
			.then(response => {
				this.setState({ currentAPIMessage: 'Remote open response: ' + JSON.stringify(response) });
			})
			.catch(error => {
				console.log(error.response);
			});

	}

	getChangeModeDropdownList = () => {
		const modes = this.props.productModel === 'EL301' ? ['OPEN', 'LOCK', 'EXIT', 'PERM_OPEN', 'AUTO', 'OFF'] : ['OFF', 'AUTO', 'EXIT', 'PARTIALLY_OPEN', 'PERMANENTLY_OPEN']
		return <DropdownMenu>
			{modes.map((m, i) => <DropdownItem key={i} onClick={() => { this.sendChangeMode(m) }}>{m}</DropdownItem>)}
		</DropdownMenu>
	}

	sendChangeMode = desiredMode => {
		if (!window.confirm('Are you sure you wish to change mode on ' + this.props.doorName + '?')) return;
		const body = { id: this.props.uuid, topic: 'execute', message: JSON.stringify({ command: 'CHANGE_MODE', desiredMode }) };

		this.setState({ showAlert: true, currentAPIMessage: 'Attempting to send change mode command' });

		API
			.post("utility", "publish", { body })
			.then(response => {
				console.log(response);
				this.setState({ currentAPIMessage: 'Change mode response: ' + JSON.stringify(response) });
			})
			.catch(error => {
				console.log(error.response);
				this.setState({ currentAPIMessage: 'Change mode response: Error' });
			});

	}

	manuallyAssignFW = () => {
		this.setState({ showDS: !this.state.showDS });
	}

	executeFWUpdate = () => {

		if (!window.confirm('Are you sure you wish to update ' + this.props.doorName + ' to latest firmware?')) return;

		if (!this.props.ethMAC) return;

		console.log('clicked on update');
		let body = { "updatePreference": "autoUpdate", "id": this.props.uuid };
		this.setState({ showAlert: true, currentAPIMessage: JSON.stringify(body) });
		const myInit = {
			body: body
		};

		API
			.post("ignite", "asset/firmware/preferences", myInit)
			.then(response => {
				this.setState({ currentAPIMessage: JSON.stringify(response.action, null, 2) });
			})
			.catch(error => {
				console.log(error.response);
			});

	}

	handleAlertDismiss = () => {
		this.setState({
			showAlert: false, currentAPIMessage: '',
			showingHawkbitStatus: false
		});
	}

	setRealTimeUpdate = (startRealTime) => {

		let cmd = startRealTime ? "DOORSTATE_UPDATE_START" : "DOORSTATE_UPDATE_STOP";
		let body = {
			"id": this.props.uuid, "topic": "execute",
			"message": "{\"command\": \"" + cmd + "\"}"
		};
		this.setState({ showAlert: true, currentAPIMessage: JSON.stringify(body) });
		const myInit = {
			body: body
		};

		API
			.post("utility", "publish", myInit)
			.then(response => {
				console.log(response);
				try {
					let body = response.body;
					if (body === "ok") {
						console.log('reply is ok');
						this.setState({ currentAPIMessage: 'reply of DOORSTATE_UPDATE: OK' });
					}
				} catch (e) {

				}
			})
			.catch(error => {
				console.log(error.response);
			});
	}

	toggleRealTimeData = () => {
		if (!this.state.showRealTimeData) {
			// if the connector does not have the required
			// firmware to support real time data streaming, 
			// display this and return
			if (this.props.fwVersionNum < 410) {
				this.setState({
					showAlert: true,
					currentAPIMessage: 'Firmware ' + this.props.fwVersionNum + ' does not support real time streaming'
				}
				);
				return;
			}
			if (!this.props.isShadowFresh) {
				if (!window.confirm('This connector does not seem to be sending data. Starting real time streaming will likely have no effect. Are you sure you wish to proceed?')) return;
			}
		}
		this.setState({ showRealTimeData: !this.state.showRealTimeData })
		this.setRealTimeUpdate(!this.state.showRealTimeData)
	}

	render() {
		return (

			<div>
				{this.state.showAlert && (
					<Alert variant="primary" onClose={this.handleAlertDismiss} dismissible>
						{this.state.currentAPIMessage}
					</Alert>
				)}
				{this.state.showingHawkbitStatus && (
					<HawkbitInt ethMAC={this.props.ethMAC} methodName="actions" />
				)}
				{this.state.showDS && (
					<HawkbitInt doorName={this.props.doorName} ethMAC={this.props.ethMAC} methodName="distributionsets" />
				)}
				<p> </p>&nbsp;&nbsp;
				<Button data-tip data-for="rebootTip" color="primary" onClick={this.rebootConnector}>
					Reboot
				</Button>   <ReactTooltip id="rebootTip" place="top" effect="solid">
					Reboot the connector
				</ReactTooltip> &nbsp;&nbsp;
				<Button data-tip data-for="openTip" color="primary" onClick={this.sendRemoteOpen}>
					Open
				</Button> <ReactTooltip id="openTip" place="top" effect="solid">
					Open the door
				</ReactTooltip> &nbsp;&nbsp;
				<Button data-tip data-for="logsTip" color="primary" onClick={this.getLogs}>
					Get Logs
				</Button> <ReactTooltip id="logsTip" place="top" effect="solid">
					Get IoT logs
				</ReactTooltip> &nbsp;&nbsp;
				<Button data-tip data-for="hawkbitTip" color="primary" onClick={this.checkHawkbitStatus}>
					Hawkbit check
				</Button> <ReactTooltip id="hawkbitTip" place="top" effect="solid">
					Check when connector last pinged Hawkbit
				</ReactTooltip> &nbsp;&nbsp;
				<Button data-tip data-for="autoUpdateTip" color="primary" onClick={this.executeFWUpdate}>
					Auto update
				</Button> <ReactTooltip id="autoUpdateTip" place="top" effect="solid">
					Assign latest firmware
				</ReactTooltip> &nbsp;&nbsp;
				<Button data-tip data-for="autoUpdateTip" color="primary" onClick={this.manuallyAssignFW}>
					Assign FW
				</Button> &nbsp;&nbsp;
				{this.props.productModel &&
					<Dropdown data-tip data-for="changeModeTip" style={{ display: 'inline' }} isOpen={this.state.changeModeDropdownOpen} toggle={() => this.setState({ changeModeDropdownOpen: !this.state.changeModeDropdownOpen })}>
						<DropdownToggle color="primary" caret>
							Change mode
						</DropdownToggle>
						{this.getChangeModeDropdownList()}
					</Dropdown>
				}
				<ReactTooltip id="changeModeTip" place="top" effect="solid">
					Change mode on the door
				</ReactTooltip> &nbsp;&nbsp;
				<Button data-tip data-for="toggleTealTime" color="primary" onClick={this.toggleRealTimeData.bind(this)}>
					{this.state.showRealTimeData ? 'Stop' : 'Start'} Real-time data</Button>
				<ReactTooltip id="toggleTealTime" place="top" effect="solid">
					Start/stop real time data stream
				</ReactTooltip>
				{this.state.showRealTimeData && <WebSocketComponent uuid={this.props.uuid} />}
			</div>
		);
	}
}

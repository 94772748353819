import React, { Component } from "react";
import AssetData from "../utility/AssetData";

export default class FirmwareDisplay extends Component {
    static defaultProps = {
        firmwareVersion: null,
        fullDisplay: false,
        config: null
	}
    
    extractVersionFromFullString = () => {
        let config = this.props.config;
        if (config.hasOwnProperty('firmwareVersion')) {
			let prettyName = config.firmwareVersion.split("PRETTY_NAME=\"");
			return prettyName[1].replace('Buildroot', '').replace("\"", '').replace(/[0-9]+/g, '').replaceAll(".", '').replaceAll('-', '').replaceAll('SME', '');
		} else {
            return "";
        }
    }

    render() {
        return (
            <div> {this.props.fullDisplay ? this.extractVersionFromFullString() : 
                 AssetData.extractNumberFromVersion(this.props.firmwareVersion)}</div>
        )
    };
}


export default {    
    utilityAPIGateway: {
      REGION: "ap-southeast-1",
      URL: "https://7ipo6cqxf7.execute-api.ap-southeast-1.amazonaws.com/latest/"
    },
    engageAPIGateway: {
      REGION: "us-west-2",
      URL: "https://wes54jfd95.execute-api.us-west-2.amazonaws.com/v1/"
    },
    adminAPIGateway: {
      REGION: "ap-southeast-1",
      URL: "https://dyjahlwbwe.execute-api.ap-southeast-1.amazonaws.com/test/"
    },
    firmwareUpdateAPIGateway: {
      REGION: "ap-southeast-1",
      URL: "https://v5l9wevj54.execute-api.ap-southeast-1.amazonaws.com/fw-update/"
    },
    igniteAPI: {
      REGION: "ap-southeast-1",
      URL: "https://1x6pz29hni.execute-api.ap-southeast-1.amazonaws.com/test/"
    },
    hawkbit_int: {
      REGION: "ap-southeast-1",
      URL: "https://e7664ac4cd.execute-api.ap-southeast-1.amazonaws.com/latest/"
    },
    cognito: {
      REGION: "ap-southeast-1",
      USER_POOL_ID: "ap-southeast-1_KNjfX3CBg",
      APP_CLIENT_ID: "262m4eajt62hj7dndu1v4dscmi",
    }
  };
  
import React, { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import "./LoaderButton.css";
import PropTypes from 'prop-types';

export default class LoaderButton extends Component {
  render() {
    return (<Button
      className={`LoaderButton ${this.props.className}`}
      disabled={this.props.disabled || this.props.isLoading}
      onClick={this.props.onClick}
    >
      {this.props.isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />}
      {this.props.isLoading ? this.props.loadingText : this.props.text}
    </Button>);
  }
}

LoaderButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  onClick: PropTypes.func
};

LoaderButton.defaultProps = {
  disabled: false,
  isLoading: true,
  className: "",
  text: "",
  loadingText: "",
  onClick: null
};
import React, { Component } from "react";
import moment from 'moment';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

export default class LastUpdated extends Component {
    render() {
        return (
            <div className="LastUpdated">
                <span>Last updated as at <Moment local>{this.props.date}</Moment> (<Moment fromNow>{this.props.date}</Moment>),
                will auto-refresh in <Moment toNow ago add={{ minutes: this.props.refreshInterval }}>{this.props.date}</Moment>.
                </span>
            </div>
        );
    }
}

LastUpdated.propTypes = {
    date: PropTypes.instanceOf(moment),
    refreshInterval: PropTypes.number
};

LastUpdated.defaultProps = {
    date: moment(),
    refreshInterval: 15
};
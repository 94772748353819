import React, { Component } from "react";
import { Spinner } from 'reactstrap';
import { API } from "aws-amplify";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);


const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
	},
	scales: {
		y: {
			type: 'linear',
			ticks: {
				stepSize: 1,
			},
		},
	},
};

export default class CustomerStats extends Component {

	constructor(props) {
		super(props);

		this.state = {
			data: null,
			hasData: false,
		}
	}
	static defaultProps = {
		customerName: null,
	}

	componentDidMount() {
		this.fetchData(this.props.customerName);
	}

	fetchData(customerName) {
		//console.log('coming to fetch data: ' + customerName);
		API.post("utility", "customers/stats", {
			body: {
				"customerName": customerName,
			}
		}).then(response => {
			//console.log(response);
			try {
				this.transformData(customerName, response.body);
			} catch (e) {
				console.log(e);
			}
		})
			.catch(error => {
				console.log(error.response);
			});
	}

	transformData(customerName, result) {
		// if customerName = '*', show it as "All assets"
		if (customerName === '*') {
			customerName = "All assets";
		}
		let labels = ['Total', 'Online', 'ES200', 'ED100', 'ED250', 'ES-Proline', 'EL301', 'KTC-2', 'KT-Flex'];
		let counts = [result.totalAssets, result.onlineCount, result.es200Count, result.ed100Count,
			result.ed250Count, result.es2020Count, result.el301Count, result.ktc2Count, result.ktflexCount]

		let _data = {
			labels,
			datasets: [
				{				
					label: customerName,	
					data: counts,
					borderColor: ["red", "blue", "green", "blue", "red", "blue", "black", "green", "red"],
					backgroundColor: ["red", "blue", "green", "blue", "red", "blue", "black", "green", "red"],
				}
			],

		};

		this.setState({ data: _data, hasData: true });
	}

	render() {
		return (
			<div>
				{this.state.hasData && <Bar options={options} data={this.state.data} />}
				{!this.state.hasData && <Spinner
						as="span"
						animation="grow"
						size="sm"
						role="status"
						aria-hidden="true"
					/>}

			</div>
		);
	}
}
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import AssetData from '../utility/AssetData';

const ONLINE_DIFF = 16 * 60;
let markers = [];
let markerClusterer = null;

export default class MapView extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showOnlyOnline: false,
			activeCenter: null,
			activeZoom: null,
			activeUUID: "",
			activeX: 0,
			activeY: 0,
			mouseOnOnline: false,
			_map: null,
			_maps: null,
			numMarkers: -1,
		}
	}

	static defaultProps = {
		center: {
			lat: 32,
			lng: 36
		},
		zoom: 2,
		data: []
	};

	isConnectorOnline(a) {
		if (a) {
			var updt = a.shadowUpdated;
			if (updt) {
				var now = Math.floor(Date.now() / 1000);
				//console.log(updt + " " + now);
				var diff = Math.abs(now - updt);
				if (diff <= ONLINE_DIFF) {
					//console.log(diff + ': online');
					return true;
				} else {
					//console.log(diff + ': offline');
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	onMarkerClick(label) {
		this.props.handler(label);
	}

	constructMarkers(geos, map, maps) {
		try {
			// first remove any markers from map if they exist
			if (markerClusterer) {
				//console.log('markers exist, removing from map');
				markerClusterer.clearMarkers();
			}

			markers = [];
			let bounds = new this.state._maps.LatLngBounds();;
			for (var i = 0; i < geos.length; i++) {
				let geo = geos[i];
				if (geo) {
					var _geo = geo.geo;
					var xy = _geo.split(",");
					var x = xy[0].trim();
					var y = xy[1].trim();
					let position = { lat: parseFloat(x), lng: parseFloat(y) };
					if (bounds) {
						bounds.extend(position);
					}
					let label = geo.uuid;
					const marker = new maps.Marker({
						position,
						label,
					});
					const infowindow = new maps.InfoWindow({
						content: AssetData.formatForInfoWindow(geo),
					});
					marker.addListener("click", () => {
						this.onMarkerClick(label);

					});
					marker.addListener("mouseover", () => {
						infowindow.open({
							anchor: marker,
							map,
							shouldFocus: false,
						});
					});
					marker.addListener("mouseout", () => {
						infowindow.close();
					});

					markers.push(marker);
				}
			}
			if (bounds) {
				this.state._map.fitBounds(bounds);
			}
			// update the number of markers if this is the first time
			if (this.state.numMarkers < 0) {
				this.setState({ numMarkers: markers.length });
			}

			// Add a marker clusterer to manage the markers.
			markerClusterer = new MarkerClusterer({ markers, map });
		} catch (e) {
			console.log('exception in constructMarkers: ' + JSON.stringify(e));
		}

	}

	defaultZoomAndCenter(callbackHandler = true) {

		try {
			// re-populate markers if the length is less than original
			let geos = [];
			if (this.props.data && markers.length !== this.state.numMarkers) {
				geos = AssetData.formatForMapsMarker(this.props.data);
				this.constructMarkers(geos, this.state._map, this.state._maps);
			}
			if (callbackHandler) {
				this.props.handler('');
			}

			//bring map back to default zoom and center
			this.setState({
				activeUUID: "",
				activeX: 0,
				activeY: 0,
				activeZoom: this.props.zoom,
				activeCenter: this.props.center
			});
			this.state._map.setCenter(this.props.center);
			this.state._map.setZoom(this.props.zoom);
		} catch (e) {
			console.log('exception in defaultZoomAndCenter: ' + JSON.stringify(e));
		}
	}

	arrayContainsUUID(arr, uuid) {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i].props.uuid === uuid) {
				return true;
			}
		}
		return false;
	}

	recenterMapBySelected = (geos) => {
		if (this.state._maps) {
			this.constructMarkers(geos, this.state._map, this.state._maps);
		}

	}

	goToMapLoc = (uuid, lat, lng, zoom = 18, showMarker = true) => {
		var currLoc = { 'lat': parseFloat(lat), 'lng': parseFloat(lng) };
		// if this is being called on a marker that is currently not visible (offline), make it visible
		this.setState({
			activeZoom: zoom,
			activeCenter: currLoc,
			activeX: showMarker ? lat : 0,
			activeY: showMarker ? lng : 0,
			activeUUID: showMarker ? uuid : '',
		});
		this.props.handler(uuid);

	}

	onBoundsChange({ zoom, center }) {
		//console.log(zoom);
		//console.log(JSON.stringify(center));
	}

	setGoogleMapRef(map, maps) {
		this.setState({ _map: map, _maps: maps });
		let geos = [];
		if (this.props.data) {
			geos = AssetData.formatForMapsMarker(this.props.data);
			//console.log('setGoogleMapRef::geos.length = ' + geos.length.toString());
			this.constructMarkers(geos, map, maps);
		} else {
			console.log('this.props.data is not defined yet');
		}
	}

	render() {


		return (
			// Important! Always set the container height explicitly
			<div style={{ padding: '2%', height: '100vh', width: '100%' }}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: 'AIzaSyDR5qLJnH5sIvD-bkiFEGaLMMcGuL6Dzic' }}
					defaultCenter={this.props.center}
					defaultZoom={this.props.zoom}
					zoom={this.state.activeZoom}
					center={this.state.activeCenter}
					onChange={this.props.changeAssetList}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => this.setGoogleMapRef(map, maps)}
				>
				</GoogleMapReact>
			</div>
		);
	}
}
// src/WebSocketComponent.js
import React, { useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Auth } from 'aws-amplify';

const WebSocketComponent = ({uuid}) => {
	const [messages, setMessages] = useState([]);
	const [connectionStatus, setConnectionStatus] = useState('Disconnected');

	useEffect(() => {
		const fetchIdToken = async () => {
			try {
				const session = await Auth.currentSession();
				const idToken = session.getAccessToken().getJwtToken();
				const ws = new ReconnectingWebSocket('wss://h0mv0ja3n7.execute-api.us-west-2.amazonaws.com/test?Authorization=' + idToken);
				ws.addEventListener('open', () => {
					console.log(uuid);
					setConnectionStatus('Connected');
				});

				ws.addEventListener('close', () => {
					setConnectionStatus('Disconnected');
				});

				ws.addEventListener('message', (event) => {
					//console.log(event);
					const newMessage = event.data;
					try {
						let msg = JSON.parse(newMessage);
						// add to message if its meant for the currently opened asset
						if (msg.UUID === uuid) {
							setMessages(JSON.stringify(msg, null, 2));
						}
 					} catch (e) { }
				});

				return () => {
					ws.close();
				};
			} catch (error) {
				console.log('Error fetching ID token', error);
			}
		};

		fetchIdToken();
	}, []);

	return (
		<div>
			<h3>WebSocket Connection Status: {connectionStatus}</h3>
			<textarea name="arbitPublish"
					defaultValue={messages}
					rows={10}
					cols={100}/>
		</div>
	);
};

export default WebSocketComponent;

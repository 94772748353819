import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import LastUpdated from "../components/LastUpdated"
import AssetTable from "./AssetTable";
import moment from 'moment';
import PropTypes from 'prop-types';
import pkg from "../../package.json";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      activeAssets: [],
      lastUpdated: moment()
    };
  }

  static defaultProps = {
    refreshInterval: 15
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.unsubscribeRefresh();
  }

  async fetchData() {
    try {
      const response = await this.activeAssets();
      const activeAssets = response.body.result.activeAssets;
      this.setState({ activeAssets, lastUpdated: moment(), isLoading: false });
    } catch (e) {
       alert(e);
      //window.location.reload();
    }
    this.subscribeRefresh();
  }

  subscribeRefresh() {
    this.unsubscribeRefresh();
    this.interval = setInterval(() => {
      this.onClickRefresh();
    }, this.props.refreshInterval * 60000);
  }

  unsubscribeRefresh() {
    clearInterval(this.interval);
  }

  activeAssets() {
    return API.get("utility", "assets");
  }

  renderActiveAssetsList(activeAssets) {
    return (<AssetTable data={activeAssets} />);
  }

  onClickRefresh = async event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ isLoading: true });
    await this.fetchData();
  }

  render() {
    return (
      <Container fluid className="assets mt-3">
        <Row>
          <Col><h2>Active Assets</h2></Col>
        </Row>
        <Row>
          <Col md={9} xs={12}>
            <LastUpdated
              date={this.state.lastUpdated}
              refreshInterval={this.props.refreshInterval}
            />
          </Col>
          <Col md={3} xs={12}>
            <LoaderButton
              disabled={this.state.isLoading}              
              isLoading={this.state.isLoading}
              text="Refresh"
              loadingText="Retrieving data..."
              className="float-right"
              onClick={this.onClickRefresh}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {!this.state.isLoading && this.renderActiveAssetsList(this.state.activeAssets)}
          </Col>
        </Row>
        <Row> {!this.state.isLoading && <p>  Version {pkg.version}</p> }</Row>
      </Container>
    );
  }
}

Home.propTypes = {
  refreshInterval: PropTypes.number
};

Home.defaultProps = {
  refreshInterval: 15
};
import React, { Component } from "react";
import AssetData from '../utility/AssetData';
export default class StatusIndicator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastShadowUpdated: ''
        }
    }
    
    render() {
        return (
            <div className="text-center">
                {AssetData.getNumTimescaleAgo(this.props.date)}
            </div>
        );
    }
}

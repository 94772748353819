import React, { Component } from "react";
import { Button, Spinner } from 'reactstrap';
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
	},
};

const ONE_DAY = 24 * 60 * 60;
const THREE_HOURS = 3 * 60 * 60;

export default class HistoricalData extends Component {

	constructor(props) {
		super(props);

		this.state = {
			data: null,
			hourlyData: null,
			tenMinuteData: null,
			isHourlyLoading: true,
			isMinuteLoading: true,
			hourlyHasData: false,
			tenMinuteHasData: false,
			hourlyHasError: false,
			alertsData: null,
			tsEndOfDay: this.endOfDayTs(),
		}
	}

	static defaultProps = {
		uuid: null,
	}

	componentDidMount() {
		//console.log(this.props.uuid);
		this.setState({ tsNow: Math.round(Date.now() / 1000) });
		this.fetchAllData();
	}

	refetchData() {
		this.setState({tsNow: Math.round(Date.now() / 1000), isHourlyLoading: true, isMinuteLoading: true,
									hourlyHasData: false});
		this.fetchAllData();
	}

	endOfDayTs() {
		let endOfDay = new Date();
		endOfDay.setHours(23, 59, 0, 0);
		return Math.round(endOfDay / 1000);
	}

	extractDate(ts) {
		var dt = new Date(ts * 1000);
		return dt.getDate() + "." + (dt.getMonth() + 1).toString() + "." + dt.getFullYear();
	}

	extractHour(ts, minuteResolution = false) {
		var dt = new Date(ts * 1000);
		if (minuteResolution) {
			return dt.getHours() + ":" + dt.getMinutes();
		} else {
			return dt.getHours();
		}

	}

	transformMinuteData(result) {
		let labels = [];
		let hourlyCycles = [];
		let i = 0;
		let cyclesInLastTenMinute = 0;
		result.forEach((item) => {
			let dt = item.Data;
			if (dt.hasOwnProperty('totalCycles')) {
				labels.push(this.extractHour(item.Timestamp, true));
				if (i > 0) {
					hourlyCycles.push(dt.totalCycles - cyclesInLastTenMinute);
				} else {
					hourlyCycles.push(0);
				}
				i++;
				cyclesInLastTenMinute = dt.totalCycles;
			}
		});

		let _tenMinuteData = {
			labels,
			datasets: [
				{
					label: 'Total cycles (per 10 mins)',
					data: hourlyCycles,
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				}
			],
		};
		this.setState({ tenMinuteData: _tenMinuteData, isMinuteLoading: false, tenMinuteHasData: true });

	}

	transformHourlyData(result) {
		let labels = [];
		let totalCycles = [];
		let hourlyCycles = [];
		let i = 0;
		let cyclesInLastHour = 0;
		let todayDate = this.extractDate(this.state.tsEndOfDay);
		result.forEach((item) => {
			let dt = item.data;
			if (dt.hasOwnProperty('totalCycles')) {
				labels.push(this.extractHour(item.timestamp));
				totalCycles.push(dt.totalCycles);
				if (i > 0) {
					//console.log(result[i].data + ' ' + result[i-1]);
					//hourlyCycles.push(result[i] - result[i - 1]);
					hourlyCycles.push(dt.totalCycles - cyclesInLastHour);
				} else {
					hourlyCycles.push(0);
				}

				i++;
				cyclesInLastHour = dt.totalCycles;
			}
		});

		let _data = {
			labels,
			datasets: [
				{
					label: 'Total cycles - ' + todayDate,
					data: totalCycles,
					borderColor: 'rgb(255, 99, 132)',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				}
			],

		};

		let _hourlyData = {
			labels,
			datasets: [
				{
					label: 'Total cycles (per hour) - ' + todayDate,
					data: hourlyCycles,
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				}
			],
		};


		this.setState({ data: _data, hourlyData: _hourlyData, isHourlyLoading: false, 
			hourlyHasData: true, hourlyHasError: false });
		//console.log(this.state.data);
	}	

	fetchAllData() {
		this.fetchHourlyData();
		this.fetchAlertsData();
	}

	endOfCurrentSelectedDay() {
		return this.state.tsEndOfDay - ONE_DAY;
	}

	todayData() {
		this.setState({ tsEndOfDay: this.endOfDayTs() }, () => {
			this.fetchAllData();
		});
	}

	previousDay() {
		this.setState({ tsEndOfDay: this.state.tsEndOfDay - ONE_DAY }, () => {
			this.fetchAllData();
		});
	}

	nextDay() {
		if (this.state.tsEndOfDay + ONE_DAY <= this.endOfDayTs()) {
			this.setState({ tsEndOfDay: this.state.tsEndOfDay + ONE_DAY }, () => {
				this.fetchAllData();
			});
		}
	}

	fetchMinuteData() {
		this.setState({ isMinuteLoading: true, tenMinuteHasData: false });
		let tsNow = Math.round(Date.now() / 1000);
		let tsThreeHoursAgo = Math.round(tsNow - THREE_HOURS);
		API
			.get("engage", "asset/snapshots/minute?Id=" + this.props.uuid + "&from=" + tsThreeHoursAgo + "&to=" + tsNow + "&minute=10")
			.then(response => {
				this.transformMinuteData(response);
			})
			.catch(error => {
				console.log(error);
			});
	}

	fetchHourlyData() {
		this.setState({ isHourlyLoading: true, hourlyHasData: false });
		let tsOneDayAgo = this.state.tsEndOfDay - ONE_DAY;
		API
			.get("engage", "asset/snapshots/hourly?Id=" + this.props.uuid + "&from=" + tsOneDayAgo + "&to=" + this.state.tsEndOfDay)
			.then(response => {
				this.transformHourlyData(response);

			})
			.catch(error => {
				console.log(error);
				this.setState({ isHourlyLoading: false, hourlyHasError: true });
			});
	}

	fetchAlertsData() {
		let tsOneDayAgo = this.state.tsEndOfDay - ONE_DAY;
		this.setState({alertsData: ""});
		API
			.get("engage", "asset/alerts?Id=" + this.props.uuid + "&from=" + tsOneDayAgo + "&to=" + this.state.tsEndOfDay)
			.then(response => {
				console.log('alerts data');
				let alertData = response.alerts;
				//console.log(JSON.stringify(alertData));
				this.setState({ alertsData: this.transformAlertsData(alertData) });
				//this.setState({ alertsData: JSON.stringify(alertData) });

			})
			.catch(error => {
				console.log("no alerts or error");
				this.setState({alertsData: "No alerts in this time period"});
			});
	}

	transformAlertsData(alertsArray) {
		let transData = "";
		for (var i = 0; i < alertsArray.length; i++) {
			let alert = alertsArray[i];
			let subAlerts = alert.alerts;
			let dt = new Date(alert.timestamp * 1000);
			transData += dt.getHours() + ":" + dt.getMinutes();//alert.timestamp;
			for (var j = 0; j < subAlerts.length; j++) {
				let currAlert = subAlerts[j];
				if (currAlert.from !== null) {
					let alertMsg = currAlert.from === false ? "Triggered" : "Cleared";
					//transData.push(currAlert.field + " " + alertMsg + " " + dt);
					transData += " " + currAlert.field + " " + alertMsg + "<br />";
				} else {
					transData += " " + currAlert.field + " null alert<br />";
				}
			}

		}
		return transData;
	}

	dateSelected(date) {
		let ts = Date.parse(date) / 1000;
		console.log("fetchAll: " + ts);
		this.setState({ tsEndOfDay: ts }, () => {
			this.fetchAllData();
		});
	}

	render() {
		return (
			<div>
				<div className="row">
					<div className="col">
						<div className="row">
							<div className="col">
								{!this.state.isHourlyLoading && <DatePicker width="200" selected={this.state.tsEndOfDay * 1000}
									onChange={(date) => this.dateSelected(date)} />}
							</div>
							<div className="col">
								{!this.state.isHourlyLoading && <Button onClick={this.todayData.bind(this)}> Today </Button>}
								&nbsp;
								{!this.state.isHourlyLoading && <Button onClick={this.previousDay.bind(this)}> &#8678; </Button>}
								&nbsp;
								{!this.state.isHourlyLoading && <Button onClick={this.nextDay.bind(this)}> &#8680;  </Button>}
								&nbsp;
								{!this.state.isHourlyLoading && <Button onClick={this.refetchData.bind(this)}> &#10227;  </Button>}
								{this.state.isHourlyLoading && <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" className="mr-2" />}
							</div>
						</div>
						<div className="row">
							{this.state.hourlyHasData && <Line data={this.state.data} />}
							{!this.state.isHourlyLoading && this.state.hourlyHasError && <p style={{ fontSize: '30px' }}> No data </p>}
						</div>
					</div>
					<div className="col">
						<div className="row">
							{this.state.isHourlyLoading && <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" className="mr-2" />}
						</div>
						<div className="row">
							<br />
							<br />
							<br />
							<br />
							{this.state.hourlyHasData && <Bar options={options} data={this.state.hourlyData} />}
						</div>
					</div>
					<div className="col">
						<div style={{ width: '500px', height: '400px', overflow: 'scroll' }}>
							<p dangerouslySetInnerHTML={{__html: this.state.alertsData}} />
						</div>
					</div>
				</div>
			</div>
		);
	}

}